import axios from '@/libs/axios'

class Transaction {
  static list(params) {
    return axios.post('user/fund-transactions', params)
  }

  static transfer(params) {
    return axios.post('user/fund-transaction/transfer', params)
  }

  static async download(params) {
    const URLparams = new URLSearchParams(Object.entries(params))
    window.open(`${process.env.VUE_APP_API_BASE_URL}user/fund-transactions/export?${URLparams}`, '_blank')
  }
}

export default Transaction
