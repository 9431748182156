<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateFrom"
              :max="filter.dateTo"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeFrom"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateTo"
              :min="filter.dateFrom"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeTo"
              locale="en"
              show-seconds
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('operator_account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.operatorAccount"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('transaction_account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.transactionAccount"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
          <b-button
            variant="success float-right"
            class="mr-1"
            @click="exportData"
            v-text="$t('export')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th
                        v-if="userData.role !== 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="$t('operator_account')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('account')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('transaction_type')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('transaction_account')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('time')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('before')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('amount')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('after')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('memo')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in transactions"
                      :key="item.id"
                    >
                      <!-- Operator Account -->
                      <td class="vgt-left-align">
                        <span v-text="item.operator_account" />
                      </td>
                      <!-- Account -->
                      <td class="vgt-left-align">
                        <span v-text="item.account" />
                      </td>
                      <!-- Account -->
                      <td class="vgt-left-align">
                        <span
                          v-text="$t(item.transaction_type.split(' ').join('_').toLowerCase())"
                        />
                        <feather-icon
                          v-if="item.transaction_type === 'Transfer To Child'"
                          style="margin-left: 5px; color: red;"
                          icon="ArrowRightIcon"
                        />
                        <feather-icon
                          v-if="item.transaction_type === 'Transfer To Agent'"
                          style="margin-left: 5px; color: red;"
                          icon="ArrowRightIcon"
                        />
                        <feather-icon
                          v-if="item.transaction_type === 'Transfer From Agent'"
                          style="margin-left: 5px; color: blue;"
                          icon="ArrowLeftIcon"
                        />
                        <feather-icon
                          v-if="item.transaction_type === 'Transfer From Child'"
                          style="margin-left: 5px; color: blue;"
                          icon="ArrowLeftIcon"
                        />
                      </td>
                      <!-- Transaction Account -->
                      <td class="vgt-left-align">
                        <span v-text="item.transaction_account" />
                      </td>
                      <!-- Time -->
                      <td class="vgt-left-align">
                        <span v-text="item.time" />
                      </td>
                      <!-- Amount Before -->
                      <td class="vgt-right-align">
                        <span v-text="item.amount_before" />
                      </td>
                      <!-- Amount -->
                      <td class="vgt-right-align">
                        <span v-text="item.amount" />
                      </td>
                      <!-- After -->
                      <td class="vgt-right-align">
                        <span v-text="item.amount_after" />
                      </td>
                      <!-- memo -->
                      <td class="vgt-left-align">
                        <span v-text="item.memo" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <custom-pagination
            v-model="filter.page"
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="filter.page"
            @page-changed="loadData"
            @change-item-per-page="changeItemPerPage"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BFormInput,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'

import Transaction from '@/models/Transaction'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CustomPagination from '@/components/table/CustomPagination.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportFundTransactions',
  components: {
    CustomPagination,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      agentId: null,
      transactions: [],
      loading: false,
      perPage: 10,
      totalItems: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        operatorAccount: typeof this.$route.query.operatorAccount !== 'undefined' ? this.$route.query.operatorAccount : '',
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        transactionAccount: typeof this.$route.query.transactionAccount !== 'undefined' ? this.$route.query.transactionAccount : '',
        dateFrom: typeof this.$route.query.dateFrom !== 'undefined' ? this.$route.query.dateFrom : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        dateTo: typeof this.$route.query.dateTo !== 'undefined' ? this.$route.query.dateTo : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        timeFrom: typeof this.$route.query.timeFrom !== 'undefined' ? this.$route.query.timeFrom : '00:00:00',
        timeTo: typeof this.$route.query.timeTo !== 'undefined' ? this.$route.query.timeTo : '23:59:59',
      },
    }
  },
  mounted() {
    this.loadData()
    if (this.userData.role !== 'partner') {
      this.columns.unshift({
        label: 'operator_account',
        field: 'operator_account',
        type: 'text',
        filterOptions: {
          enabled: false,
          placeholder: 'Search',
        },
      })
    }
  },
  methods: {
    async exportData(a) {
      a.preventDefault()
      try {
        await Transaction.download({ ...this.filter, agentId: this.agentId })
      } catch (e) {
        this.$alertHelper.error()
      }
    },
    async changeItemPerPage(itemPerPage) {
      this.perPage = itemPerPage
      this.loadData(1)
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      this.filter.perPage = this.perPage
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Transaction.list(searchParams)
      if (response.data.status) {
        this.transactions = response.data.data
        this.totalItems = response.data.meta.pagination.total
        this.perPage = response.data.meta.pagination.per_page
        this.agentId = response.data.meta.agent.realId
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    filterByAccount(account) {
      this.filter.account = account
      this.loadData()
    },
  },
}
</script>

<style>
</style>
